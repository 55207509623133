.search-bar {
    display: flex;
    align-items: center;
}

.search-bar input {
    padding: 5px;
    margin-right: 10px;
}

.search-bar button {
    background: none;
    border: none;
    cursor: pointer;
    margin: 0 5px;
}

.search-bar button i {
    font-size: 18px;
}

.icon-eye-open::before {
    content: '👁️';
}

.icon-eye-closed::before {
    content: '🙈';
}

.icon-sort::before {
    content: '⇅';
}

.icon-filter::before {
    content: '🔍';
}

.icon-stack::before {
    content: '📚';
}