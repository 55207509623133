.ant-picker-dropdown {
  z-index: 2250 !important;
}

@media (max-width: 586px) {
  .search-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .navbar {
    justify-content: center !important;
    align-items: center !important;
  }
}